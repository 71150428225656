import SkinSensitivityTemplate from '@/modules/questionnaire/components/steps/common/medical-background/skin-sensitivity/SkinSensitivityTemplate';

import { BOOLEAN, SAFE_CLEANSER } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/MedicalBackground/SkinSensitivity/SkinSensitivityTemplate',
  component: SkinSensitivityTemplate
};

const createStory = props => () => ({
  components: { SkinSensitivityTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><skin-sensitivity-template v-bind="$options.storyProps" /></div>'
});

export const base = createStory({
  skinReactsToProducts: BOOLEAN.YES,
  safeCleansers: [
    SAFE_CLEANSER.WIPES,
    SAFE_CLEANSER.CLEANSING_OIL,
    SAFE_CLEANSER.SOAP,
    SAFE_CLEANSER.CLEANSING_MILK,
    SAFE_CLEANSER.MICELLAR_WATER,
    SAFE_CLEANSER.CREAM_CLEANSER
  ]
});

export const unselected = createStory({
  skinReactsToProducts: BOOLEAN.NO,
  safeCleansers: []
});

export const someCleansers = createStory({
  skinReactsToProducts: BOOLEAN.NO,
  safeCleansers: [SAFE_CLEANSER.CREAM_CLEANSER, SAFE_CLEANSER.SOAP, SAFE_CLEANSER.WIPES]
});
